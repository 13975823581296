.logo {
  height: 40px;
  text-align: center;
  font-weight: bold;
  color: #fafafa;
  font-size: 16pt;
  cursor: pointer;
  width: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.logo-comau {
  height: 60px;
  text-align: center;
  font-weight: bold;
  color: #fafafa;
  font-size: 16pt;
  cursor: pointer;
  width: 54px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.logo-user {
  height: 54px;
  text-align: center;
  font-weight: bold;
  color: #fafafa;
  font-size: 16pt;
  cursor: pointer;
  width: 42px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.MuiListItemIcon-root {
  min-width: 30px !important;
  margin-right: 2px !important;
}

.margin {
  margin-left: 25%;
}

.menu-icon {
  max-width: 20px !important;
}

._title {
  margin: 2% auto 2% 0;
}

body {
  font-size: 12pt !important;
  font-family: Arial, sans-serif;
  font-weight: normal;
}

.MuiFormLabel-root.Mui-disabled {
  color: rgba(84, 84, 84, 0.5) !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 1px solid rgba(0, 0, 0, 0);
  background-color: #1f3366 !important;
  color: #ffffff !important;
  z-index: -1 !important;
  border-right: 6px solid #56a6f5 !important;
}

.MuiDrawer-paperAnchorLeft {
  left: 0;
  right: auto;
  background-color: #1f3366 !important;
  color: #ffffff !important;
  z-index: 0 !important;
  border-right: 6px solid #56a6f5 !important;
}

.MuiDrawer-paperAnchorLeft {
  flex-shrink: 0;
  padding-left: 5px;
  padding-right: 5px;
  width: 240px;
  background-color: #1f3366 !important;
}

.MuiTableCell-root {
  padding: 10px !important;
}

.MuiAppBar-colorPrimary {
  background-color: #56a6f5 !important;
  color: #1f3366 !important;
  box-shadow: none !important;
}

.header {
  justify-content: flex-start;
  min-height: 60px !important;
  max-height: 60px !important;
}

.MuiListItem-root {
  margin-bottom: 4px !important;
  margin-left: 4px !important;
  min-height: 54px !important;
}

.arrow {
  width: 20px;
}
