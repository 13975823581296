.my-updateinstallation-container {
  padding: 2% 10% 2% 10%;
  margin: 4% 15% 2% 15%;
  border: 1px solid #d9d9d9;
  border-radius: 1%;
}

@media only screen and (max-width: 575px) {
  .my-updateinstallation-container-responsive {
    margin: 4% 2% 2% 2% !important;
  }
}

.my-option-container {
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

.btn-container {
  margin-top: 6%;
  display: flex;
  justify-content: space-evenly;
}

.card-opt {
  margin-left: 16px;
  margin-right: 16px;
}

.card {
  border: 1px solid #d9d9d9;
  margin-bottom: 32px;
}
