.profileDescriptionContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.profileDescription {
  margin-top: 10vh;
  margin-bottom: 3%;
  width: 70%;
  padding: 1%;
}

@media only screen and (max-width: 575px) {
  .profileDescriptionResponsive {
    width: 95% !important;
  }
}
