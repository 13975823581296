[class^="MUIDataTableHeadCell-fixedHeader-"] {
  top: 0;
  z-index: 0 !important;
  position: inherit !important;
}

[class^="MUIDataTable-"] {
  padding: 2% !important;
}

.MuiTableCell-head {
  font-weight: 500;
  line-height: 1.5rem;
  background-color: #56a6f5 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  white-space: nowrap !important;
  color: white !important;
}

.MuiTableRow-root {
  color: white !important;
  display: table-row;
  outline: 0;
  vertical-align: middle;
  background-color: #3255ab !important;
  border: 1px solid #3255ab !important;
  margin-bottom: 4px !important;
}

.custom-tag {
  text-align: center;
  min-width: 90px;
  max-height: 22px;
  overflow: hidden;
}
