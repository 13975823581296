.form__group {
	position: relative;
	margin-top: 8%;
	margin-bottom: 8%;
}

.form__field {
    width: 100%;
    border: 0;
    border-bottom: 2px solid #CCCCCC;
    outline: 0;
    background: transparent;
    transition: border-color 0.2s;
}

.form__field::placeholder {
    color: rgb(175, 175, 175) !important;
}

.form__field:focus::placeholder {
	color: transparent !important;
}

.form__field:placeholder-shown ~ .form__label {
    cursor: text;
    top: 20px;
}

.form__label {
  	display: block;
  	transition: 0.2s;
	font-weight: 600;
}

.form__label:focus {
    display: block;
    transition: 0.2s;
    color: #1890ff !important;
}    

.form__field:focus{
    border-color: #1890ff;
    border-image-slice: 1;
	border-width: 3px;
}