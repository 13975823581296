.my-data-container {
  padding: 2% 10% 2% 10%;
  margin: 4% 15% 2% 15%;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 1%;
}

.datepicker {
  width: 100%;
}

.btn-container {
  margin-top: 6%;
  display: flex;
  justify-content: space-evenly;
}

@media only screen and (max-width: 575px) {
  .my-data-container-responsive {
    margin: 4% 2% 2% 2% !important;
  }
}

.exportForm {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

::-webkit-calendar-picker-indicator {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(36deg) brightness(200%)
    contrast(0%);
}
