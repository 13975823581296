html * {
  font-family: "Futura Std", sans-serif !important;
  font-weight: lighter;
}

[class*="jss"] {
  z-index: 1 !important;
}

.tableTitle {
  margin-top: 3.6vh !important;
  margin-bottom: 5vh !important;
  display: flex;
  justify-content: center;
  color: #cd8133 !important;
  font-size: large;
  cursor: default;
}

.cardTitle {
  padding-top: 32px !important;
  margin-top: 3.6vh !important;
  margin-bottom: 5vh !important;
  display: flex;
  justify-content: center;
  color: #cd8133 !important;
  font-size: large;
  cursor: default;
}

.divider {
  margin-bottom: 4vh !important;
  border: 1px #cd8133 solid !important;
}

@media only screen and (max-width: 575px) {
  .titleContainerResponsive {
    width: 90% !important;
    display: flex;
    justify-content: space-between;
  }
}

.titleContainer {
  margin-top: 3.6vh !important;
  width: 63%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleBack {
  display: flex;
  justify-content: center;
  color: #cd8133 !important;
  font-size: large;
  cursor: default;
}

.back-button {
  width: "200%";
}

.subscriptionInfo {
  display: flex;
  justify-content: space-evenly;
}

.content {
  padding: 2% 10% 2% 10%;
  width: 100%;
  background-color: white;
}

[class^="MUIDataTableHeadCell-"] {
  font-weight: bold !important;
  white-space: nowrap !important;
}

.headerContainer {
  height: 68px !important;
  position: fixed;
  width: 90%;
  z-index: 10;
}

.headerResponsive {
  height: 68px !important;
  display: flex !important;
  justify-content: space-between !important;
  position: fixed;
  width: 90%;
  z-index: 10;
}

body {
  margin: 0;
  font-family: "Futura Std", sans-serif !important;
  font-weight: lighter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Futura Std", sans-serif !important;
  font-weight: lighter;
}

.cell {
  display: flex;
  justify-content: space-between;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

html {
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent; /* make scrollbar transparent */
}

@media only screen and (max-height: 414px) {
  .titleContainerHeigth {
    width: 75% !important;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.MuiListItem-gutters {
  padding-left: 2px !important;
  padding-right: 16px;
}

.MuiButtonBase {
  height: 54px !important;
}

.MuiAppBar-colorPrimary {
  z-index: 1000 !important;
}

.details-container {
  display: flex;
  align-items: center;
}

.details {
  text-align: center;
}

.MuiAlert-root {
  background-color: #1f3366 !important;
  border: 4px #3255ab solid !important;
}
