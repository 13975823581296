.button {
  margin-top: 1%;
  margin-bottom: 1%;
}

.description {
  display: flex;
  justify-content: flex-start;
}

.info {
  margin-left: 1%;
  margin-right: 1%;
}

.form-installer {
  display: flex;
  justify-content: space-around;
  margin-top: 2%;
}

.select-item {
  width: 40%;
}

.btn-confirm-container {
  display: flex;
  justify-content: center;
}

.btn-confirm {
  margin-top: 2%;
  margin-bottom: 4%;
  width: 15%;
  min-width: 190px;
}
