.loading-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 30%;
}

.button-container{
    margin-top: 6%;
    display: flex;
    justify-content: space-evenly; 
    padding-bottom: 2%;
}