.my-connection-container {
  padding: 2% 10% 2% 10%;
  margin: 4% 15% 2% 15%;
  border: 1px solid #d9d9d9;
  border-radius: 1%;
}

.btn-container {
  margin-top: 6%;
  display: flex;
  justify-content: space-evenly;
}

.progress {
  margin-top: 8%;
}
